import Container_RectangleAreaComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/RectangleArea/RectangleArea.skin';


const Container_RectangleArea = {
  component: Container_RectangleAreaComponent
};


export const components = {
  ['Container_RectangleArea']: Container_RectangleArea
};


// temporary export
export const version = "1.0.0"
